<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>Bộ lọc</h5>
    </b-card-header>
    <b-card-body>
      <b-row class="align-items-end">
        <b-col>
          <label>Tên nhân viên</label>
          <b-form-input
            :value="nameFilter"
            class="d-inline-block mr-1"
            placeholder="Search..."
            @input="(val) => updateFilter('update:nameFilter', val)"
          />
        </b-col>
        <b-col cols="12" md="4" class="mb-md-0 mb-2">
          <label>Tên đăng nhập</label>
          <b-form-input
            :value="usernameFilter"
            class="d-inline-block mr-1"
            placeholder="Search..."
            @input="(val) => updateFilter('update:usernameFilter', val)"
          />
        </b-col>
        <b-col>
          <b-button variant="primary" @click="$emit('clear-filter')">
            <span class="text-nowrap">Hủy lọc</span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BButton, BCardHeader, BCardBody, BRow, BCol, BFormInput } from 'bootstrap-vue';

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BCardHeader,
    BCardBody,
    BFormInput,
  },
  props: {
    nameFilter: {
      type: [String, null],
      default: null,
    },
    usernameFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      debounce: null,
    };
  },
  methods: {
    debounceChange(eventKey, value) {
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        this.$emit(eventKey, value);
      }, 500);
    },
    updateFilter(eventKey, value) {
      this.debounceChange(eventKey, value);
    },
  },
};
</script>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('users-list-filters',{attrs:{"name-filter":_vm.nameFilter,"username-filter":_vm.usernameFilter},on:{"update:nameFilter":function($event){_vm.nameFilter=$event},"update:name-filter":function($event){_vm.nameFilter=$event},"update:usernameFilter":function($event){_vm.usernameFilter=$event},"update:username-filter":function($event){_vm.usernameFilter=$event},"clear-filter":_vm.clearFilter}}),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Show")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("entries")])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(
                _vm.$can(
                  _vm.permissions.USER.POST_v1_users.action,
                  _vm.permissions.USER.POST_v1_users.resource
                )
              )?_c('b-button',{attrs:{"to":{ name: _vm.constRouter.USER_STAFF_NEW.name },"variant":"primary"}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Thêm nhân viên")])]):_vm._e()],1)])],1)],1),_c('b-table',{ref:"refUserListTable",staticClass:"position-relative",attrs:{"items":_vm.fetchUsers,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(user)",fn:function(data){return [_c('b-media',{attrs:{"vertical-align":"center"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"32","src":data.item.avatar,"text":_vm.avatarText(data.item.fullName),"variant":("light-" + (_vm.randomColor())),"to":{
                name: _vm.constRouter.USER_STAFF_DETAIL.name,
                params: { id: data.item.id },
              }}})]},proxy:true}],null,true)},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap",attrs:{"to":{
              name: _vm.constRouter.USER_STAFF_DETAIL.name,
              params: { id: data.item.id },
            }}},[_vm._v(" "+_vm._s(data.item.name)+" ")]),_c('small',{staticClass:"text-muted"},[_vm._v("@"+_vm._s(data.item.username))])],1)]}},{key:"cell(actions)",fn:function(data){return [(
            _vm.$can(
              _vm.permissions.USER.GET_v1_users__user_id.action,
              _vm.permissions.USER.GET_v1_users__user_id.resource
            )
          )?_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"variant":"flat-success","to":{
            name: _vm.constRouter.USER_STAFF_DETAIL.name,
            params: { id: data.item.id },
          }}},[_c('feather-icon',{attrs:{"id":("invoice-row-" + (data.item.id) + "-preview-icon"),"icon":"EyeIcon","size":"16"}})],1):_vm._e(),_c('b-tooltip',{attrs:{"title":"Xem chi tiết","target":("invoice-row-" + (data.item.id) + "-preview-icon")}}),_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(
              _vm.$can(
                _vm.permissions.USER.PUT_v1_users__user_id.action,
                _vm.permissions.USER.PUT_v1_users__user_id.resource
              )
            )?_c('b-dropdown-item',{attrs:{"to":{
              name: _vm.constRouter.USER_STAFF_EDIT.name,
              params: { id: data.item.id },
            }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Sửa")])],1):_vm._e()],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" entries")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalUsers,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
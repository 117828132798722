import { ref, watch, computed } from '@vue/composition-api';

// Notification
import { useToast } from 'vue-toastification/composition';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { title } from '@core/utils/filter';
import { list } from '@/apis/apiStaff';

export default function useStaffList() {
  // Use toast
  const toast = useToast();

  const refUserListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: 'user', label: 'Nhân viên', sortable: true },
    // { key: 'phone', label: 'Số điện thoại', sortable: true },
    {
      key: 'username',
      label: 'Tên đăng nhập',
      sortable: true,
    },
    {
      key: 'email',
      label: 'Email',
      sortable: true,
    },
    {
      key: 'created_at',
      label: 'Ngày Tạo',
      formatter: title,
      sortable: true,
    },
    {
      key: 'isActive',
      label: 'Kích hoạt',
      sortable: true,
    },
    { key: 'actions', label: 'Thao tác' },
  ];
  const perPage = ref(10);
  const totalUsers = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const sortBy = ref('id');
  const isSortDirDesc = ref(true);
  const usernameFilter = ref(null);
  const nameFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    };
  });

  const refetchData = () => {
    refUserListTable.value.refresh();
  };

  watch([currentPage, perPage, usernameFilter, nameFilter], () => {
    refetchData();
  });

  const fetchUsers = (ctx, callback) => {
    list({
      page: currentPage.value,
      limit: perPage.value,
      order: sortBy.value,
      sortOrder: isSortDirDesc.value ? -1 : 1,

      full_name: nameFilter.value,
      username: usernameFilter.value,
    })
      .then((response) => {
        const { data, meta } = response.data;

        callback(data);
        totalUsers.value = meta.total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Lỗi tải danh sách nhân viên',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      });
  };

  return {
    fetchUsers,
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    refetchData,

    // Extra Filters
    nameFilter,
    usernameFilter,
  };
}

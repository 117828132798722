<template>
  <div>
    <!-- Filters -->
    <users-list-filters
      :name-filter.sync="nameFilter"
      :username-filter.sync="usernameFilter"
      @clear-filter="clearFilter"
    />

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                v-if="
                  $can(
                    permissions.USER.POST_v1_users.action,
                    permissions.USER.POST_v1_users.resource,
                  )
                "
                :to="{ name: constRouter.USER_STAFF_NEW.name }"
                variant="primary"
              >
                <span class="text-nowrap">Thêm nhân viên</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative"
        :items="fetchUsers"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="avatarText(data.item.fullName)"
                :variant="`light-${randomColor()}`"
                :to="{
                  name: constRouter.USER_STAFF_DETAIL.name,
                  params: { id: data.item.id },
                }"
              />
            </template>
            <b-link
              :to="{
                name: constRouter.USER_STAFF_DETAIL.name,
                params: { id: data.item.id },
              }"
              class="font-weight-bold d-block text-nowrap"
            >
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.username }}</small>
          </b-media>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-if="
              $can(
                permissions.USER.GET_v1_users__user_id.action,
                permissions.USER.GET_v1_users__user_id.resource,
              )
            "
            variant="flat-success"
            class="btn-icon rounded-circle"
            :to="{
              name: constRouter.USER_STAFF_DETAIL.name,
              params: { id: data.item.id },
            }"
          >
            <feather-icon
              :id="`invoice-row-${data.item.id}-preview-icon`"
              icon="EyeIcon"
              size="16"
            />
          </b-button>
          <b-tooltip title="Xem chi tiết" :target="`invoice-row-${data.item.id}-preview-icon`" />

          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item
              v-if="
                $can(
                  permissions.USER.PUT_v1_users__user_id.action,
                  permissions.USER.PUT_v1_users__user_id.resource,
                )
              "
              :to="{
                name: constRouter.USER_STAFF_EDIT.name,
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Sửa</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BLink,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BAvatar,
  BFormCheckbox,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import { avatarText } from '@core/utils/filter';
import constRouter from '@/constants/constRouter';
import permissions from '@/constants/permissions';
import { editStatus } from '@/apis/apiStaff';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import UsersListFilters from './StaffListFilters.vue';
import useStaffList from './useStaffList';

export default {
  components: {
    UsersListFilters,
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BAvatar,
    BMedia,
    BLink,
    BFormCheckbox,
    vSelect,
  },
  setup() {
    const {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserStatusVariant,

      // Extra Filters
      nameFilter,
      usernameFilter,
    } = useStaffList();

    return {
      fetchUsers,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserStatusVariant,

      // Extra Filters
      nameFilter,
      usernameFilter,

      constRouter,
      permissions,
    };
  },
  data: () => ({
    colors: ['primary', 'warning', 'success', 'info', 'danger'],
  }),
  methods: {
    clearFilter() {
      this.nameFilter = '';
      this.usernameFilter = '';

      this.refetchData();
    },
    randomColor() {
      return this.colors[Math.floor(Math.random() * 5)];
    },
    changeStatus(status, id) {
      editStatus(id, status)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sửa nhân viên',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'Cập nhật trạng thái thành công!',
            },
          });
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Sửa nhân viên',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Cập nhật trạng thái thất bại!',
            },
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
